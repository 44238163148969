import { lazy, Suspense, FC, ComponentType } from "react";
import { JSX } from "react/jsx-runtime";

const applySuspense = (Component: FC) => (props: JSX.IntrinsicAttributes) =>
  (
    <Suspense fallback={"loading..."}>
      <Component {...props} />
    </Suspense>
  );

export const loadable = (module: { (): Promise<{ default: ComponentType }> }) =>
  applySuspense(lazy(module));
