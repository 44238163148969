import { ComponentType } from "react";
import { loadable } from "../../utils/loadable";

// const TagsCreate = loadable(
//   () => import(/* webpackChunkName: "TagsCreate" */ "./TagsCreate")
// );
// const TagsEdit = loadable(
//   () => import(/* webpackChunkName: "TagsEdit" */ "./TagsEdit")
// );
const TagsCreate = loadable(
  () =>
    import(/* webpackChunkName: "TagsCreate" */ "./TagsCreate") as Promise<{
      default: ComponentType;
    }>
);
const TagsEdit = loadable(
  () =>
    import(/* webpackChunkName: "TagsEdit" */ "./TagsEdit") as Promise<{
      default: ComponentType;
    }>
);

export const tagsManagement = [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/tags/create",
      element: <TagsCreate />
    }
  },
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/tags/:tagId/edit",
      element: <TagsEdit />
    }
  }
];
