import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import NavContent from "./NavContent";

// Fix Toolbar z-index
const HotfixToolbar = () => {
  useEffect(() => {
    setTimeout(() => {
      const element: HTMLElement | null = document.querySelector(
        "#root > div > div > div > header:nth-child(2)"
      );
      if (element) {
        try {
          element.style.zIndex = "2";
        } catch {}
      }
    }, 1500);
  }, []);
  return null;
};

export const dashboard = () => [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/",
      element: <Navigate to={`tags`} />
    }
  },
  {
    target: "$ONE_LAYOUT_NAV_CONTENT",
    handler: {
      component: NavContent
    }
  },
  // Fix Toolbar z-index
  {
    target: "$ONE_LAYOUT_ROUTES_AFTER",
    handler: {
      component: HotfixToolbar
    }
  }
];
