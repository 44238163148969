import { loadable } from "../../utils/loadable";

const TagsView = loadable(
  () => import(/* webpackChunkName: "TagsView" */ "./TagsView")
);
export const tagsView = [
  {
    target: "$ONE_LAYOUT_ROUTE",
    handler: {
      exact: true,
      path: "/tags",
      element: <TagsView />
    }
  }
];
