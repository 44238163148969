import { Link } from "react-router-dom";

import Box from "@vapor/react-material/Box";
import List from "@vapor/react-material/List";
import ListItem from "@vapor/react-material/ListItem";
import ListItemIcon from "@vapor/react-material/ListItemIcon";
import ListItemText from "@vapor/react-material/ListItemText";
import Tooltip from "@vapor/react-material/Tooltip";

import Grid from "@vapor/react-icons/Grid";

const NavContent = () => {
  return (
    <Box role="presentation">
      <List>
        <Tooltip arrow placement="right" title="Errors">
          <ListItem button component={Link} to={`errors`}>
            <ListItemIcon>
              <Grid size="20px" color="inverse" />{" "}
            </ListItemIcon>
            <ListItemText primary={"Errors"} />
          </ListItem>
        </Tooltip>
        <Tooltip arrow placement="right" title="Tags">
          <ListItem button component={Link} to={`tags`}>
            <ListItemIcon>
              <Grid size="20px" color="inverse" />{" "}
            </ListItemIcon>
            <ListItemText primary={"Tags"} />
          </ListItem>
        </Tooltip>
      </List>
    </Box>
  );
};

export default NavContent;
