import pkg from "../package.json";
import { LicenseInfo } from "@mui/x-license-pro";

/**
 * OneFront SDK
 * ============
 *
 * This package provides most of the Services that you may need
 * in a OnePlatform Application.
 *
 * You write simple React Components and interact with the SDK
 * using modern hooks API.
 *
 * The SDK guides you into dividing you App into isolated Features
 * that you will glue together using ForrestJS APIs.
 *
 * REACT-STARTED
 * Swap comment on line 19/20 to use React Starter
 *
 * NOTE: You must create a company for your TSID:
 * https://appdev.teamsystemdigital.com/
 * (it takes up to 20 minutes to work... keep reloading...)
 */
//import onefront from "@onefront/react-sdk";
import onefront from "@drift/oneplatfront"; // will become @onefront/react-starter

/**
 * Import the App's Features
 * =========================
 *
 * A Feature is an isolated portion of business logic that
 * interacts with the App's Services
 */

import { cqrs } from "./services/cqrs";
import { dashboard } from "./features/dashboard";
import { errorsView } from "./features/errors-view";
import { tagsView } from "./features/tags-view";
import { tagsManagement } from "./features/tags-management";
import { vaporTheme } from "./theme";

// @TODO: evaluate wether to apply envalid or similar validation
//        or no default in production and hard crash
const APP_ID = process.env.REACT_APP_APP_ID || "demo";

const proxyBaseUrl = process.env.REACT_APP_PROXY_BASE_URL || "/api";

console.log(
  `AppId: ${APP_ID}; Version: ${pkg.version}; proxyBaseUrl: ${proxyBaseUrl}`
);

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_PRO_TOKEN ?? "");

/**
 * Configure and Run the App
 * =========================
 *
 * The boot process returns a Promise that you can handle
 * to figure out whether all was good or not.
 */
onefront
  .run({
    // trace: "compact",
    settings: {
      one: {
        auth: {
          token: {
            refresh:
              (process.env.REACT_APP_TOKEN_FORCE_REFRESH || "yes") === "yes"
          }
        },
        rest: {
          graphql: {
            baseUrl: "scm::tags-management::hasuraApi/v1/graphql"
          }
        },
        login: {
          target: {
            params: ["mode=redirect", `appId=${APP_ID}`]
          }
        },
        box: {
          app: { id: "dcbe1292-007e-49c1-8e42-1c4fed662f15" },
          module: { name: "scm::tags-management::app", version: pkg.version }
        },
        i18n: {
          options: {
            fallbackLng: "it",
            defaultNS: "tags-management-app"
          }
        },
        appId: APP_ID,
        axios: {
          proxy: {
            baseUrl: proxyBaseUrl
          }
        }
      },
      tags: {
        basePath: "tags",
        urn: APP_ID
      },
      constants: {
        headers: {
          workspaceId: "x-workspace-id"
        }
      }
    },
    services: [cqrs],
    features: [dashboard, vaporTheme, errorsView, tagsView, tagsManagement]
  })
  .catch(console.error);
